import { message } from "antd";
import { urlPrepare } from "helpers";
import { SplitApiV2 } from "redux/apiv2";

export const smartfolioApi = SplitApiV2.injectEndpoints({
  endpoints: (builder) => ({
    getSmartfolios: builder.query<any, any>({
      query: ({ pageNumber, pageSize }) =>
        urlPrepare(`/smartfolios?page_number=${pageNumber}&page_size=${pageSize}`),
      providesTags: ["Smartfolios"]
    }),
    findGroups: builder.query<any, any>({
      query: () => urlPrepare(`/smartfolios/groups`)
    }),
    findMarkets: builder.query<any, any>({
      query: () => urlPrepare(`/smartfolios/markets`)
    }),
    findCategories: builder.query<any, any>({
      query: () => urlPrepare(`/smartfolios/categories`)
    }),
    findLayers: builder.query<any, any>({
      query: () => urlPrepare(`/smartfolios/layers`)
    }),
    findAssetClasses: builder.query<any, any>({
      query: () => urlPrepare(`/smartfolios/asset-classes`)
    }),
    createSmartfolio: builder.mutation<any, any>({
      query: ({ smartfolio }) => ({
        url: `/smartfolios`,
        method: "POST",
        body: smartfolio
      }),
      invalidatesTags: ["Smartfolios"]
    }),
    createSmartfolioNew: builder.mutation<any, any>({
      query: ({ smartfolio }) => ({
        url: `/smartfolios/from-scratch`,
        method: "POST",
        body: smartfolio
      }),
      invalidatesTags: ["Smartfolios"]
    }),
    getSmartfolioById: builder.query<any, any>({
      query: ({ smartfolioId }) => urlPrepare(`/smartfolios/${smartfolioId}`),
      providesTags: ["Smartfolio"]
    }),
    updateSmartfolio: builder.mutation<any, any>({
      query: ({ smartfolioId, smartfolio }) => ({
        url: `/smartfolios/${smartfolioId}`,
        method: "PUT",
        body: smartfolio
      }),
      invalidatesTags: ["Smartfolios"]
    }),
    deleteSmartfolio: builder.mutation<any, any>({
      query: ({ smartfolioId }) => ({
        url: `/smartfolios/${smartfolioId}`,
        method: "DELETE"
      }),
      invalidatesTags: ["Smartfolios"]
    }),
    createLink: builder.mutation<any, any>({
      query: ({ smartfolioId, body, classificationType }) => ({
        url: `/smartfolios/${smartfolioId}/link/${classificationType}`,
        method: "POST",
        body: body
      }),
      transformResponse: () => {
        message.success("Link created successfully");
      },
      invalidatesTags: ["Smartfolio"]
    }),
    updateLink: builder.mutation<any, any>({
      query: ({ smartfolioId, body, classificationType, classificationId }) => ({
        url: `/smartfolios/${smartfolioId}/link/${classificationType}/${classificationId}`,
        method: "PUT",
        body: body
      }),
      transformResponse: () => {
        message.success("Link updated successfully");
      },
      invalidatesTags: ["Smartfolio"]
    }),
    deleteLink: builder.mutation<any, any>({
      query: ({ smartfolioId, classificationType, classificationId }) => ({
        url: `/smartfolios/${smartfolioId}/link/${classificationType}/${classificationId}`,
        method: "DELETE"
      }),
      transformResponse: () => {
        message.success("Link deleted successfully");
      },
      invalidatesTags: ["Smartfolio"]
    }),
    cloneSmartfolio: builder.mutation<any, any>({
      query: ({ smartfolioId }) => ({
        url: `/smartfolios/${smartfolioId}/clone`,
        method: "POST"
      }),
      invalidatesTags: ["Smartfolios"]
    }),
    getSmartfolioNav: builder.query<any, any>({
      query: ({ smartfolioId }) => urlPrepare(`/smartfolios/${smartfolioId}/nav`)
    })
  })
});

export const {
  useGetSmartfoliosQuery,
  useCreateSmartfolioMutation,
  useCreateSmartfolioNewMutation,
  useDeleteSmartfolioMutation,
  useUpdateSmartfolioMutation,
  useFindAssetClassesQuery,
  useFindCategoriesQuery,
  useFindGroupsQuery,
  useFindLayersQuery,
  useFindMarketsQuery,
  useGetSmartfolioByIdQuery,
  useCreateLinkMutation,
  useUpdateLinkMutation,
  useDeleteLinkMutation,
  useCloneSmartfolioMutation,
  useGetSmartfolioNavQuery
} = smartfolioApi;
